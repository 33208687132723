<template>
  <router-view style="height: 100% !important" />
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",
  computed: mapState({
    settings: (state) => state.settings.data,
  }),

  data: () => ({
    //
  }),
  created() {
    var pathArray = window.location.pathname.split("/");
    if (
      this.settings &&
      (!this.settings.login_at ||
        !this.$moment(this.settings.login_at).isSame(this.$moment(), "day")) &&
      pathArray[2] != "start" &&
      pathArray[2] != "login" &&
      pathArray[2] != "demo"
    ) {
      var locale = pathArray[1];
      if (
        !locale ||
        locale == "" ||
        (locale != "" && !(locale == "en" || locale == "zh" || locale == "ms"))
      ) {
        window.location.href = window.location.origin + "/en/start";
      } else {
        window.location.href = window.location.origin + "/" + locale + "/start";
      }
      this.$router.push({ name: "PageMainLogin" });
    }
  },
};
</script>
<style>
html {
  background-blend-mode: lighten;
  background-image: url("assets/background/BGTextureLite.jpg");
  background-size: cover;
}
</style>
