export default {
  action: {
    back: "Back",
    buy: "Buy",
    cancel: "Cancel",
    changeLanguage: "Change Language",
    checkResult: "Check Result",
    claim: "Claim",
    claimed: "Claimed",
    claimReward: "Claim Reward",
    clickToContinue: "Click to continue",
    clickToClaim: "Click to claim",
    continue: "Continue",
    copied: "COPIED",
    delete: "Delete",
    done: "Done",
    draw: "Draw",
    enter: "Enter",
    find: "Find",
    go: "Go",
    goOn: "Go On",
    game: "Try full version game",
    hatch: "Hatch",
    home: "Home",
    info: "Info",
    joinNow: "Join Now",
    login: "Log In",
    loginAgain: "Log In",
    loginAsStudent: "Login as a student",
    loginAsTeacher: "Login as a teacher",
    logout: "Log Out",
    new: "New",
    next: "Next",
    nextTime: "Maybe Next Time",
    noThx: "No Thanks",
    ok: "OK",
    orderBy: "Order By",
    play: "Play",
    playAgain: "Play Again",
    recalculate: "Recalculate",
    refresh: "Refresh",
    regenerateQuestion: "Regenerate Questions",
    register: "Register",
    remove: "Remove",
    reset: "Reset",
    returnHome: "Return to Home",
    retry: "Retry",
    rewardClaimed: "Reward Claimed",
    save: "Save",
    search: "Search",
    select: "Select",
    selected: "Selected",
    scoreboard: "Scoreboard",
    share: "Share",
    skip: "SKIP",
    skipTutorial: "skip tutorial",
    start: "Start",
    testPlay: "Play",
    trade: "TRADE",
    tryAgain: "Try Again",
    unlock: "Unlock",
    view: "View",
    viewSample: "View Sample",
    whatsapp: "Whatsapp Us",
  },
  api: {
    response: {
      no_data: "No data available.",
    },
  },
  buildingEntranceDialogues: {
    welcomeBugCatcher_1: "Welcome to Bug Catcher!",
    welcomeBugCatcher_2: "Welcome to Bug Catcher!",
    welcomeBugCatcher_3: "Welcome to Bug Catcher!",
    welcomeEnergy_1: "Welcome to Energy Building!",
    welcomeEnergy_2: "Welcome to Energy Building!",
    welcomeEnergy_3: "Welcome to Energy Building!",
    welcomeEvents_1: "Want a hot dog?",
    welcomeEvents_2: "This place is always crowded.",
    welcomeEvents_3: "What's going on today?",
    welcomeHouse_1: "Welcome to House!",
    welcomeHouse_2: "Welcome to House!",
    welcomeHouse_3: "Welcome to House!",
    welcomeLuckyegg_1: "Welcome to Lucky Egg Shop!",
    welcomeLuckyegg_2: "Welcome to Lucky Egg Shop!",
    welcomeLuckyegg_3: "Welcome to Lucky Egg Shop!",
    welcomeMission_1: "Welcome to Homeworks!",
    welcomeMission_2: "Welcome to Homeworks!",
    welcomeMission_3: "Welcome to Homeworks!",
    welcomeReport_1: "Welcome to Report Card!",
    welcomeReport_2: "Welcome to Report Card!",
    welcomeReport_3: "Welcome to Report Card!",
    welcomeShop_1: "Welcome to shop!",
    welcomeShop_2: "Welcome to shop!",
    welcomeShop_3: "Welcome to shop!",
    welcomeSchool_1: "Welcome to School!",
    welcomeSchool_2: "Welcome to School!",
    welcomeSchool_3: "Welcome to School!",
  },
  games: {
    random: "Random",
    noGame: "No Game",
    mining: "Mining",
    foraging: "Foraging",
    cooking: "Cooking",
    fishing: "Fishing",
  },
  miniGames: {
    general: {
      cache: "Cache",
      cacheOn: "Cache is on",
      cacheOff: "Cache is off",
      continue: "Click to Start",
      go: "GO",
      loading: "Loading",
      newGame: "New Game",
      ok: "OK",
      on: "On",
      off: "Off ",
      ready: "READY ",
      resume: "Resume",
      round: "Round",
      score: "Score",
      next: "Next",
      recommendationTxt: "Let’s learn it from video before continue",
      recommendationBtn: "Watch Now",
      restConfirmationTxt: "Are you sure you want to reset?",
    },
    mining: {
      careful: "Careful!",
      cleared: "CLEARED!",
      gemLostBoss: "GEM LOST!",
      gemLost: "GEM LOST!",
      hurryUp: "Hurry up!",
      timeFreeze: "Time Freeze!",
      timeUp: "TIME UP!",
      warning: "WARNING",
      warningMsg: "A gem thief is here!",
      tutorial: {
        dialog_1: "Welcome to the mining game!",
        dialog_2: "Let us learn how to play.",
        dialog_3: "Click the pickaxe button to start.",
        dialog_4: "Choose the correct answer",
        dialog_5_a: "Great, your answer is correct",
        dialog_5_b: "Opps.. Incorrect answer.",
        dialog_6_a: "Let’s blow up the wall with the bomb",
        dialog_6_b: "Let’s blow up the wall with the firecracker",
        dialog_7: "Kaboom!!!",
        dialog_8: "Wow, you found a buried GEM",
        dialog_9: "You need to clear the soil to recover the GEM",
        dialog_10:
          "The wall will crumble when time’s up, so you have act fast!",
        dialog_11: "Have fun and good luck!",
        dialog_12_a: "Time’s up! Better luck next time.",
        dialog_12_b: "Oh no! You broke the gem. Better luck next time.",
        dialog_12_c: "Congratulations! You got the gem. Well done!",
        dialog_13: "Let’s try one more time.",
        dialog_14: "Good job! You have completed the mining tutorial.",
        dialog_15: "Good luck and find more gem.",
      },
    },
    foraging: {
      failed: "FAILED",
      failedMsg: " Rare fruit is gone",
      success: "SUCCESS",
      successMsg: "You collected rare fruit",
      stun: "Stun",
      warning: "WARNING",
      warningMsg: "A fruit thief detected!",
      q_msg_1: "Perfect",
      q_msg_2: "Awesome",
      q_msg_3: "Great",
      q_msg_4: "Nice",
      q_msg_5: "Boo",
      tutorial: {
        dialog_1: "Welcome to Foraging Fest.",
        dialog_2: "Let us learn how to play.",
        dialog_3: "Click the start button.",
        dialog_4: "Your job is to collect fresh apples.",
        dialog_5:
          "Meanwhile, avoid the snakes, caterpillars, and rotten apples.",
        dialog_6: "Use the arrow buttons to move around.",
        dialog_7: "Good job.",
        dialog_8: "Let us start.",
        dialog_9: "Wow, you are full of energy!",
        dialog_10: "Answer the question and use your power.",

        dialog_11: "Choose the correct answer",
        dialog_12_a: "Great, your answer is correct",
        dialog_12_b: "Opps.. Incorrect answer.",
        dialog_13_a: "Let's use your power.",
        dialog_13_b: "Let's use your power.",
        dialog_14: "Let’s try one more time.",
        dialog_15: "Well done! You have completed the game tutorial.",
        dialog_16: "Good luck!",
      },
    },
    cooking: {
      boss: "Boss is Coming",
      warning: "WARNING",
      tutorial: {
        dialog_1: "Welcome to Cooking Craze",
        dialog_2: "Let us learn how to play!",
        dialog_3: "Your job is to feed the customers the food they like",
        dialog_4: "Different foods have different scores",
        dialog_5: "But, don’t let the customer wait for too long",
        dialog_6: "Ok, let us start!",
        dialog_7: "First, click on the ingredient to start cooking",
        dialog_8: "Then, wait for the food to be cooked",
        dialog_9: "Now, drag the food to the customer",
        dialog_10: "Good job, the customer is happy!",
        dialog_11: "Next, let's try to overcook the food",
        dialog_12: "Opps, the stove is on  fire!",
        dialog_13: "Quickly! Drag the fire extinguisher to put out the fire",
        dialog_14: "Then, drag the burned food into the bin",
        dialog_15: "All good, let us continue...",
        dialog_16: "Wow, your power bar is full",
        dialog_17: "Answer the question to use your power!",
        dialog_18: "Choose the correct answer.",
        dialog_19_a: "Great, your answer is correct",
        dialog_19_b: "Incorrect answer",
        dialog_20: "Let’s use your power…",
        dialog_21_a: "Quick, feed all the customers!",
        dialog_21_b: " Poof.. accident happened.",
        dialog_22: "Put out the fire quickly!",
        dialog_23: "Let’s try one more time.",
        dialog_24: "Well done! You have completed the tutorial.",
        dialog_25: "Good luck!",
      },
    },
    cooking2: {
      boss: "Boss is Coming",
      warning: "WARNING",
      tutorial: {
        dialog_1: "Welcome to Cooking Craze Penang",
        dialog_2: "Let us learn how to play!",
        dialog_3: "Your job is to feed the customers the food they like",
        dialog_4: "Don’t let the customer wait too long!",
        dialog_5: "The faster you serve, the higher your score!",
        dialog_6: "Ok, let us start!",
        dialog_7: "First, click on the ingredient to start cooking",
        dialog_8: "Then, wait for the food to be cooked",
        dialog_9: "Now, drag the food to the customer",
        dialog_10: "Good job, the customer is happy!",
        dialog_11: "Next, let's try to overcook the food",
        dialog_12: "Opps, the stove is on  fire!",
        dialog_13: "Quickly! Drag the fire extinguisher to put out the fire",
        dialog_14: "Then, drag the burned food into the bin",
        dialog_15: "All good, let us continue...",
        dialog_16: "Wow, your power bar is full",
        dialog_17: "Answer the question to use your power!",
        dialog_18: "Choose the correct answer.",
        dialog_19_a: "Great, your answer is correct",
        dialog_19_b: "Incorrect answer",
        dialog_20: "Let’s use your power…",
        dialog_21_a: "Quick, feed all the customers!",
        dialog_21_b: " Poof.. accident happened.",
        dialog_22: "Put out the fire quickly!",
        dialog_23: "Let’s try one more time.",
        dialog_24: "Well done! You have completed the tutorial.",
        dialog_25: "Good luck!",
      },
    },
    towerClimbing: {
      blueStarError: "Not enough blue stars",
      floor: "Floor",
      footprintError: "Not enough footprints",
      pickOne: "PICK ONE",
    },
    kombat: {
      boss: "Pirate Captain is coming!!!",
      warning: "WARNING ",
      lost: "DEFEATED ",
      fight: "FIGHT ",
      won: "VICTORY ",
      tutorial: {
        dialog_1: "Welcome to BIBABO",
        dialog_2: "Let us learn how to play!",
        dialog_3: "Oh, pirates are here!",
        dialog_4: "Let us defeat them.",
        dialog_22: "These are your hit points - don't let them reach to 0.",
        dialog_5: "Click the magic button to start the attack bar.",
        dialog_6: "Click again to stop.",
        dialog_7: "Fill up both attack bars.",
        dialog_8_a: "Hiyaa, eat my magic!!!",
        dialog_8_b: "Your magic failed…",
        dialog_9: "Ouch, the pirate fights back.",
        dialog_10: "Come, we continue to attack the pirate.",
        dialog_11: "Oh, the pirate is injured.",
        dialog_12: "Prepare to use your special attack.",
        dialog_13: "Click the button to continue.",
        dialog_14: "Choose the correct answer.",
        dialog_15_a: "Great, your answer is correct.",
        dialog_15_b: "Incorrect answer.",
        dialog_16_a: "Your magic is more powerful now!",
        dialog_16_b: "Oh no, you miss your chance.",
        dialog_21: "Attack!!!",
        dialog_17_a: "Good job, you have defeated the pirate!",
        dialog_17_b: "Oh no, you fainted!!!",
        dialog_18: "Better luck next time.",
        dialog_19: "You have completed the tutorial.",
        dialog_20: "Good luck!",
      },
    },
    fishing: {
      boss: "Boss fish detected!!!",
      fish_1: "Box Fish",
      fish_2: "Shrimp",
      fish_3: "Clam",
      fish_4: "Dory",
      trash: "Trash",
      warning: "WARNING ",
      start: "START",
      tutorial: {
        dialog_1: "Welcome to Fishing Frenzy.",
        dialog_2: "I am Hamochi, I will teach you how to fish!",
        dialog_3:
          "Click the big yellow pointy light bulb button to start fishing.",
        dialog_4_1: "The fish is on the hook!",
        dialog_4_2: "Let's see what challenge it gives!",
        dialog_5: "Choose the correct answer.",
        dialog_6_a: "Great, your answer is correct.",
        dialog_6_b: "Opps! Incorrect answer.",
        dialog_12_a: "Your fishing rod is stronger now!",
        dialog_12_b: "Oh no, your fishing rod has become weaker!",
        dialog_7: "Quick, press the big yellow button to pull the fish up!",
        dialog_8_a: "Congratulations, you caught the fish!",
        dialog_8_b: "Too bad, the fish slipped away...",
        dialog_9: "Let’s practice again!",
        dialog_10: "Good job, you have completed the fishing tutorial.",
        dialog_11: "I wish you all the best!",
      },
    },
    dino: {
      oops: "Oops...",
      notEnough: "Not enough bones!",
      niceWork: "Nice Work!",
      gameOver: "Game Over!",
      dragDrop: "Drag and Drop",
    },
  },
  model: {
    filterBy: {
      all: "All",
    },
    name: {
      chapter: "Chapter",
      chapters: "Chapters",
      checkpoint: "Question",
      checkpoints: "Questions",
      classroom: "Classroom",
      classrooms: "Classrooms",
      event: "Contest",
      events: "Contests",
      game: "Game",
      mission: "Homework",
      missions: "Homeworks",
      prize: "Prize",
      prizes: "Prizes",
      question: "Question",
      questions: "Questions",
      results: "Results",
      school: "School",
      schools: "Schools",
      series: "Series",
      student: "Student",
      students: "Students",
      submissions: "Submissions",
      syllabus: "Syllabus",
      syllabuses: "Syllabuses",
      winner: "Winner",
      winners: "Winners",
    },
    prop: {
      accuracy: "Accuracy",
      attempt: "Attempts",
      bought: "Bought",
      corrects: "Corrects",
      date: "Date",
      dateDue: "Due Date",
      dateEnd: "End Date",
      dateStart: "Start Date",
      fullname: "Full Name",
      gameCode: "Game Code",
      high_score: "High Score:",
      level: "Level",
      mobile: "Mobile Number",
      name: "Name",
      no: "#",
      password: "Password",
      quantity: "Quantity",
      rewards: "Rewards",
      round: "Round",
      schoolCode: "School Code",
      score: "Score",
      stars: "Stars",
      status: "Status",
      time: "Time",
      timeEnd: "End Time",
      timestampEnd: "End",
      timestampStart: "Start",
      totalAttempt: "Total Attempts",
      totalScore: "Total Score",
      totalStar: "Total Stars",
      weakness: "Weakness",
    },
    value: {
      status: {
        absent: "Absent",
        coming: "Upcoming",
        joined: "Joined",
        ongoing: "Ongoing",
        past: "Past",
      },
    },
  },
  route: {
    PageEntranceStudentMain: "Home",
  },
  string: {
    about: "About",
    all: "All",
    achievement: "Achievement",
    achievements: "Achievements",
    are_you_sure: "Are you sure?",
    assisting_lang: "Assisting Language",
    assisting_lang_on: "Assisting Language is on",
    assisting_lang_off: "Assisting Language is off",
    audio: "Audio",
    audioGraphics: "Audio & Graphics",
    authorPre: "By",
    authorPost: "",
    autofix: "Auto Fix",
    autoplay: "Autoplay",
    avatarLevel: "Level",
    bgMusic: "Background Music",
    blankSpace: " ",
    book: "BOOK",
    bookInfo: "Book Info",
    body: "Animal",
    bug: "Bugs",
    by: "by",
    doll: "Dolls",
    dino: "Dinosaurus",
    catch: "Catch",
    cancel: "Cancel",
    claimed: "Claimed",
    classmates: "Classmates",
    chapter: "Chapter",
    chapterUnlocked: "Chapter Unlocked",
    chapters: "Chapters",
    change: "Change",
    code: "Code",
    color: "Color",
    collection: "Collection",
    continueIn: "Continue in {sec} seconds",
    completed: "Completed",
    coming_on: "Coming on",
    comingSoon: "Coming Soon",
    confirm_msg: "You wish to end the game?",
    chapterStarMsg1: "Collect more stars to unlock this chapter",
    chapterStarMsg2: "Complete previous chapter to unlock this chapter",
    days: "days",
    days_left: "days left",
    days_start: "days countdown",
    current: "Current",
    easy: "Easy",
    empty: "Empty",
    empty_msg: "The inbox is empty!",
    emptyNameError: "Name can't be empty",
    energyRechargeable: "Top up your energy everyday!",
    energyClaimed: "You have claimed your energy today.",
    energySuccess: "Successfully recharged!",
    ends_in: "End in",
    expired: "Expired",
    expires_in: "Expires in",
    eventNotStarted: "The event has not started yet.",
    event_over: "Event was over!",
    eventOngoing: "Ongoing Events",
    eventCommingsoon: "Upcoming Events",
    eventUpcoming: "Future Events",
    eventLatest: "Latest Events",
    eventPast: "Past Events",
    exit: "Exit",
    noNews: "No News!",
    finished: "Finished",
    finisherGiftTitle: "Finisher Gift",
    finisherGiftMsg1: "Congratulations on completing this event!",
    finisherGiftMsg2: "Your gift is on the way to:",
    finisherGiftMsg3:
      "Your gift has been delivered! Please collect from your school.",
    finisherGiftMsg4:
      "I got you a Finisher Dift, I will sent it to your school.",
    failed: "Failed!",
    featureNotAvailable: "This feature not yet available.",
    fishingTutorial: "Fishing Tutorial",
    fishingTutorialCompleted: "You have Completed the Fishing Tutorial Lesson",
    fishingTutorialIncomplete:
      "Please complete the tutorial to unlock this level",
    fish: "Fishes",
    fish2: "Fishes",
    fruit: "Fruits",
    food: "Foods",
    food2: "Foods",
    game_loading_msg: "Game starting... If delayed, retry.",
    gameScore: "Game Score",
    gem: "Gems",
    get: "Get",
    gifts_error: "Gifts are only for kids.",
    giftDescription:
      "Collect all the stars to get a mystery gift in the real life!",
    gotIt: "New Trophy!",
    hall: "Hall",
    hard: "Hard",
    highGraphics: "High Graphics",
    thanksDemo: "Thank You for trying Hamochi ABC!",
    info: "INFO",
    high_score: "High Score:",
    league_reward: "League Rewards",
    level: "Level",
    levelUp: "LEVEL UP!",
    language: "Language",
    lastLogin: "Last login",
    levels: "LEVELS",
    lockedBuilding: "What can it be?",
    learnMoreEvent: "Learn more about this event",
    manyGifts: "Many gifts 🎁 awaits!",
    maths: "Maths",
    mathDaily: "Daily Maths",
    mathMastery: "Maths Mastery",
    medium: "Medium",
    mouth: "Mouth",
    month_theme: "Theme of the Month:",
    movedMsg: "You can find it here!",
    myBook: "My Book",
    myPrize: "My Prize",
    myPlan: "My Plan",
    myPlanStatusActive: "Active",
    newArrivals: "New Arrivals",
    no: "No",
    noEnergy: "Not enough energy",
    noItem: "No Item",
    noPick: "No Pick",
    noStudents: "No Students in this league",
    notCompleted: "Not Completed",
    noThanks: "No thanks!",
    open: "Open",
    others: "Others",
    own: "Owned",
    page: "Page",
    quest: "Quests",
    questEventBookFinishAllChapters: "Complete all chapters",
    question: "Question",
    question_prefix: "Q",
    rank: "RANK",
    ranking: "Ranking",
    rankingClassroom: "Classroom",
    rankingEvent: "Event",
    read: "Read",
    read_rules: "Read the rules",
    reader_loading_msg: "Reader is starting... If delayed, retry.",
    recalculate:
      "Use the following button if the total stars or scores are not tally",
    recalculateScore: "Are you sure you want to recalculate the score?",
    recalculateStar: "Are you sure you want to recalculate the star?",
    remove: "Remove",
    rewards_claimed: "Rewards claimed!",
    resetIn: "Reset in",
    refresh: "Refresh in",
    result: "Result",
    requiredHigh: "Required a High speed internet.",
    round: "Round",
    rules: "Rules",
    sample_question: "Sample Question",
    save: "Save",
    saving: "Saving......",
    school: "SCHOOL",
    schoolYear_prefix: "Primary ",
    schoolYear_suffix: "",
    select_year: "Select a year",
    select_hole: "Select Hole",
    selectKeyError: "Select a key to continue.",
    settings: "Settings",
    sfx: "SFX",
    show_all: "Show all",
    show_less: "Show less",
    showHint: "Show Hint",
    sponsors: "Sponsors",
    start_now: "Start Now",
    startReading: "Start Reading",
    starts: "Starts {count}",
    starts_in: "Starts in",
    storyBook: "Story Book",
    success: "Success!",
    towerMoved: "The Tower has been moved to Math!",
    theme: "Theme",
    tutorial: "Tutorial",
    ticketError:
      "Not enough tickets. Come back and collect your new tickets tomorrow.",
    total: "Total",
    totalScore: "Total Score",
    topics: "Topics",
    tryAgain: "Try Again",
    takeBreak: "Take a Break",
    youFailed: "You Failed",
    updateImages: "Update Images",
    upload_art: "Upload your art!",
    view_result: "View Results",
    volume: "Volume",
    wear: "Wear",
    warning: "Warning!",
    yes: "Yes",
    mail: "Mail",
    year: "Year {year}", 
    you_have_mail: "You have a mail",
    you_got: "You got",
    you_collected: "You have collected",
    you_missed_a: "You have missed the Boss Gem",
    you_missed_b: "You have missed the rare fruit",
    you_missed_c: "You have missed the rare dish",
    you_missed_d: "You have missed the Doll",
    you_missed_x: "You have missed the Rare Collectible",
    openIn: "Open in",
    closeIn: "Close in",
    goToTheEvent: "Go to the event",
    basicItems: "Basic Items",
    drawing: "Studio Specials",
    monthly: "Monthly Specials",
    event: "Event Specials",
    tower: "Math Tower Specials",
    go: "GO",
    chapterCompleted: "Chapter completed",
    readAgain: "Read again",
    goChapters: "Go to chapters",
  },
  title: {
    gallery: "Gallery",
    tower: "Math Tower",
    town: "Town",
    shop: "Shop",
    wardrobe: "Wardrobe",
    hamochi_cup: "Events",
    game: "Game",
    level: "Level",
    locksmith: "Locksmith",
    select_school: "Select Your School",
    studio: "Studio",
  },
  tutorial: {
    energy: {
      dialog_1: "Oh! your energy is low.",
      dialog_2: "Let's recharge.",
      dialog_3: "Click here to enter.",
      dialog_4: "Click here to recharge your energy",
      dialog_5: "Yeah. Your energy recharged.",
      dialog_6: "You can come here and recharge your energy, once a day.",
      dialog_7: "Click here to navigate to Town Island",
      dialog_8: "It seems you have already recharge for the day. Try tomorrow.",
    },
    events: {
      dialog_1: "Look, it seems to be very lively there!",
      dialog_2: "Click here to enter.",
      dialog_3: "Click here to go to Town Island.",
      dialog_4: "Ha! It turns out to be an event. Let’s join.",
      dialog_5: "Click here to join the event.",
      dialog_6: "Let’s try the lite version this time.",
      dialog_7: "Let’s try the full version this time.",
    },
    exploreIntro: {
      dialog_1: "Hi, I am Hamochi.",
      dialog_2: "Welcome to Hamochi World.",
      dialog_3: "We love to learn and play.",
    },
    exploreEvents: {
      dialog_1: "Look, it seems to be very lively there!",
      dialog_2: "Click here to enter.",
      dialog_3: "Click here to join the event.",
    },
    exploreMining: {
      dialog_1: "Let's learn how to mine gems!",
      dialog_2: "Click here to start mining.",
    },
    exploreForaging: {
      dialog_1: "Let's learn how to collect fruits!",
      dialog_2: "Click here to start foraging.",
    },
    exploreCooking: {
      dialog_1: "Let's learn how to cook!",
      dialog_2: "Click here to start cooking.",
    },
    exploreKombat: {
      dialog_1: "Let's learn how to use magics!",
      dialog_2: "Click here to start.",
    },
    exploreFishing: {
      dialog_1: "Let's learn how to Fish!",
      dialog_2: "Click here to start the game tutorial.",
    },
    exploreDino: {
      dialog_1: "Let's learn how to Discover Dino!",
      dialog_2: "Click here to start the game tutorial.",
    },
    exploreLevel: {
      dialog_1: "Now I'll teach you how to start a level",
      dialog_2: "Click here to start the level.",
      dialog_4: "Click here to start.",
    },
    exploreShop: {
      dialog_1: "The shop is open.",
      dialog_2: "Click here to enter the menu.",
      dialog_3: "Click here to enter the shop.",
      dialog_4_1: "You can use the coins for shopping.",
      dialog_4_2: "Oh, Congrats! You have already got a hat!",
      dialog_5: "Let’s try to put on something.",
      dialog_6: "First select one.",
      dialog_7: "Now click buy.",
      dialog_8: "Nice! This is the one.",
      dialog_9: "Congrats! You got a new hat!",
      dialog_10: "There are still many items. We can always come back again.",
      dialog_11: "Click here to go back",
    },
    intro: {
      dialog_1: "Hi, I am Hamochi.",
      dialog_2: "Welcome to Mochi Islands.",
      dialog_3: "We love to learn and play.",
    },
    fishing_achievements: {
      dialog_1: "Wow! Looks like you have completed the tutorial!",
      dialog_2: "Check out the surprise I have prepared for you!",
      dialog_3: "Click to see your achievements.",
      dialog_4: "Here’s a little reward for you.",
      dialog_5: "Click to claim your reward.",
      dialog_6: "Congratulations! It seems you already claimed your reward",
      dialog_7: "You can unlock other achievements to get more rewards.",
      dialog_8: "Next, I’ll teach you how to use your reward!",
      dialog_9: "Let’s return to the town island.",
      dialog_10: "Click here.",
    },
    fishing_level_one: {
      dialog_1: "Let’s try the first level.",
      dialog_2: "Click here to start the game.",
    },
    fishing_tutorial: {
      dialog_1: "Ahoy! It is a fishing contest.",
      dialog_2: "I will guide you how to fish.",
      dialog_3: "Click here.",
      dialog_4:
        "The fish is on the bait! Let’s solve the challenge it gives us.",
      dialog_5: "Select the correct answer.",
      dialog_6: "Well done.",
      dialog_7: "Oops…",
      dialog_8: "Guess which hole it is hiding.",
      dialog_9: "Congratulations! You have caught your first fish.",
      dialog_10: "Let’s try one more time.",
      dialog_11: "Congratulations on completing the training.",
      dialog_12: "I wish you all the best!",
      dialog_13: "Look, The fish is hiding in one of the holes.",
      dialog_14: "Click the butterflies to catch it.",
      dialog_15: "Congratulations! You learned how to catch butterflies.",
      dialog_16: "Ahoy! It is a Bug Catching contest.",
      dialog_17: "I will guide you how to catch Bugs.",
      dialog_18:
        "The bugs are on the fly! Let’s solve the challenge they give us.",
      dialog_19: "Look, The butterflies are started to appear.",
    },
    pick_shop: {
      dialog_1: "The shop is open.",
      dialog_2: "Let’s go!",
      dialog_3: "Click here to enter the shop",
      dialog_4: "You can use the coins for shopping.",
      dialog_5: "Let’s try to put on something.",
      dialog_6: "Nice! This is the one.",
      dialog_7: "Congrats! You got a new hat!",
      dialog_8: "There are still many items. We can always come back again.",
      dialog_9: "Oh, Congrats! You have already got a hat!",
      dialog_10: "Click here to navigate to Town Island",
    },
    return_events: {
      dialog_1: "Let’s return to the event.",
      dialog_2: "Click here to enter the building.",
      dialog_3: "Click here to return to the event",
      dialog_4: "Select the version you like to try this time.",
      dialog_5: "Click here to navigate to Town Island",
      dialog_6: "Click here to continue",
    },
  },
  view: {
    Island: {
      Town: "Town",
      Playground: "Playground",
      Market: "Market",
      Classroom: "Classroom",
      Home: "Home",
      Citizen: "Student",
      Teacher: "Teacher",
      Building: {
        BugCatcher: "Bug Catcher",
        Events: "Events",
        Energy: "Energy",
        Garden: "Garden",
        House: "House",
        LuckyEgg: "Lucky Egg",
        Mayor: "Teacher",
        News: "News",
        Report: "Report Card",
        Mission: "Homework",
        School: "School",
        Shop: "Shop",
      },
    },
    PageBuildingMission: {
      bonusRewardsMessage: "Bonus rewards end",
      claimRewardMessage: "Claim your reward.",
      completedMessage: "Completed on",
      completedPastDueMessage: "Completed past due on",
      deleteMissionMessage: "Are you sure you want to delete this homework?",
      dueMessage: "Due on",
      pageAddTitle: "New Homework",
      pageEditTitle: "Edit Homework",
      pagePerformanceTitle: "Students Performance",
      rewardNotReceivedMessage: "You got nothing",
      rewardReceivedMessage: "You received",
    },
    PageBugcatcherHome: {
      bigBugCaught: "You caught the {Bugname}",
      bigBugMissed: "You missed the big Bug! Try next time.",
      bigBugWarning: "WARNING!",
      bigBugDes: "Click 5 times to catch it.",
      bossDetected: "Boss detected",
      bugCatch: "You caught {count} butterflies this round.",
      bugMissed: "You didn't catch any butterflies this round.",
      difficultyBonus: "Difficulty Bonus",
      gameTitle: "Catch the butterflies.",
      getReady: "Get Ready",
      go: "Go",
      points: "Points",
      questionEndCorrectMessage:
        "Congratulation! You have selected correct answer.",
      questionEndWrongMessage: "Oops! You have not selected correct answer.",
      roundEndMessage: "Question time...",
    },
    PageErrorNotFound: {
      description: "Page not found.",
      title: "Oops...",
    },
    PageErrorSessionExpired: {
      description: "Please log in again.",
      title: "Your session has expired.",
    },
    PageErrorPinChanged: {
      description: "Please log in again.",
      title: "Your PIN Password is Enabled.",
    },
    PageEntranceStudentAvatarSelect: {
      title: "Create Character",
    },
    PageEntranceStudentClassroom: {
      search: "Search your name",
      stringNoAccount: "Cannot find your name?",
      title: "Select Your Name",
    },
    PageEntranceStudentMain: {
      fullVersionGameTitle: "Do you want to access full version of game?",
      stringCodeEmpty: "Game code is missing.",
      stringCodeIncomplete: "Game code is incomplete.",
      title: "Login",
    },
    PageEntranceStudentPin: {
      stringCodeEmpty: "PIN is missing.",
      stringCodeIncomplete: "PIN is incomplete.",
      title: "Enter your PIN",
    },
    PageEntranceStudentPasswordSelect: {
      title: "Select Your Password Key",
    },
    PageEntranceStudentRegister: {
      title: "Register Account",
    },
    PageEventLanding: {
      fast: "FAST",
      slow: "SLOW",
      textInternet: "Internet is ",
      textStatusEntering: "Entering in... ",
      textStatusInit: "Initiating...",
      textStatusTest: "Test internet... ",
      textVersionFull: "Full Version",
      textVersionFullDescription: "Estimate load time ",
      textVersionFullDescriptionMins: " mins",
      textVersionLite: "Lite Version",
      textVersionLiteDescription: "Recommended",
    },
    PageGuestSchool: {
      search: "Search your classroom",
      title: "Select Your Classroom",
    },
    PageParticipantLanding: {
      countdown_end: "Ends in ",
      countdown_ended: "Ended on ",
      countdown_start: "Starts in ",
      countdown_time_days: "D",
      countdown_time_hours: "H",
      countdown_time_minutes: "M",
      countdown_time_seconds: "S",
    },
    PageSleeping: {
      message:
        "It's time for bed. Hamochi and his friends are asleep from 11pm. Come back at 7am.",
    },
    PageStudentClassmate: {
      title: "My Classmates",
      description: "See your classmates",
    },
    PageStudentHome: {
      title: "Home",
      description: "What do you want to do?",
    },
    PageStudentLanding: {
      title: "Home",
      description: "Where do you want to go?",
    },
    PageStudentMedal: {
      title: "My Medals",
      description: "See Your Achievement",
    },
    PageStudentShop: {
      title: "Shop",
      description: "What you like to buy?",
    },
    PageStudentStadium: {
      title: "Stadium",
      description: "Which event you like to join?",
    },
    PageStudentWardrobe: {
      title: "Wardrobe",
      description: "What do you want to wear?",
    },
  },
  subscription: {
    name: "Bright Future Family",
    description:
      "This plan includes exciting features designed to enhance your child's learning journey:",
    feature1: "Monthly Storybooks",
    feature2: "Math Mastery",
    cta: "Learn More",
  },
};
